import { getServerFlag, getServerUrl } from './serverUrls';
import { environment } from 'constants/app';

export const baseURL = process.env.REACT_APP_API_BASE_URL;
export const isProduction = getServerFlag() === environment.prod;

export const serverUrl = getServerUrl();

export const legelLyfeCashback = isProduction
  ? 'https://cashback.lyfetech.io/'
  : 'https://lyfe.dev.networkb.co.uk/';
